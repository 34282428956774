<template>
  <div >
    <div class="md-layout-item ">
      <md-tabs :md-active-tab="current_tab">
        <md-tab id="tab-documento" md-label="Documento"  >
          <md-card >
            <form novalidate @submit.prevent>
              <md-card-header>
                <md-card-header-text v-if="view">
                  <div v-if="newPurchase" class="md-title">Nueva Compra</div>
                  <div v-else class="md-title">Editando Compra</div>
                </md-card-header-text>
                <md-card-header-text v-else>
                 <div class="md-title">Ver Compra</div>
                </md-card-header-text>
                <md-card-header-text>
                  <div class="md-title period">
                  <span v-if="!instance.id">{{ mes }}</span>
                  <span v-if="instance.id" style="  text-transform: capitalize;">
                  {{instance.period_date|to_text_date}}</span>
                </div>
                </md-card-header-text>
                <md-menu md-size="big" md-direction="bottom-end">
                  <md-button class="md-icon-button"
                  @click="go_to('/detalle_compras')">
                    <md-icon>keyboard_backspace</md-icon>
                  </md-button>
                </md-menu>
              </md-card-header>
              <md-card-content>
              <md-card-actions v-if="view">
                <md-button v-if="newPurchase"
                type="submit" class="md-primary"
                @click.prevent='validatePurchase' :disabled="sending">Guardar</md-button>
                <md-button v-else
                type="submit" class="md-primary"
                @click.prevent='validatePurchase' :disabled="sending">Guardar cambios</md-button>
              </md-card-actions>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item  " >
                    <md-field :class="getValidationClass('date_purchase')" class="md-focused">
                      <label for="date_purchase" >Fecha factura</label>
                      <md-input type="date" name="date_purchase" v-on:blur="check_date"
                      id="date_purchase" v-model="instance.date_purchase"
                      :disabled="sending || !view" />
                    </md-field>
                    <span class="md-error"
                      v-if="!$v.instance.date_purchase.required">
                      La fecha de la factura es requerida</span>
                  </div>
                  <div class="md-layout-item md-small-size-50">
                    <md-field :class="getValidationClass('serie')">
                      <label for="serie">Serie factura</label>
                      <md-input name="serie"
                        id="serie"
                        v-model="instance.serie" :disabled="sending || !view" />
                    </md-field>
                     <span class="md-error"
                      v-if="!$v.instance.serie.required">La serie de la factura es requerida</span>
                  </div>
                  <div class="md-layout-item md-small-size-50">
                    <md-field :class="getValidationClass('number_invoice_purcharse')">
                      <label for="numero">Numero factura</label>
                      <md-input name="numero"
                      id="number_invoice_purcharse" v-model="instance.number_invoice_purcharse"
                      :disabled="sending || !view" />
                    </md-field>
                    <span class="md-error"
                      v-if="!$v.instance.number_invoice_purcharse.required">
                        El número de factura es requerida</span>
                  </div>
                  <div class="md-layout-item ">
                    <div class="md-layout-item">
                      <md-field :class="getValidationClass('type_document')"
                        :title="instance.type_document_display">
                        <label for="type_document">Tipo documento</label>
                        <md-select v-model="instance.type_document"
                          name="movie"
                          id="movie"
                          :disabled="sending || !view"
                        >
                        <md-option :value="item.value" :title="item.text"
                          v-for="(item, key) in choosen_type_document"
                          :key="key">{{item.text}}</md-option>
                        </md-select>
                    </md-field>
                    <span class="md-error"
                        v-if="!$v.instance.type_document.required">
                        El tipo de documento es requerido</span>
                    </div>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <div class="md-layout-item">
                      <md-autocomplete autocomplete="off"
                        :class="getValidationClass('provider_name')"
                        id="provider_name"
                        :disabled="sending || !view"
                        v-model="instance.provider_name"
                        md-input-name="full_name"
                        :md-options="list_provider"
                        @md-selected="set_provider"
                        @md-changed="get_list_providers" >
                      <label>Proveedor</label>
                      <template
                        slot="md-autocomplete-item" slot-scope="{ item, term }">
                        <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                      </template>
                    </md-autocomplete>
                    <span class="md-error"
                      v-if="!$v.instance.provider_name.required">EL proveedor es requerido</span>
                </div>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                    <div class="md-layout-item">
                      <md-autocomplete autocomplete="off"
                        :class="getValidationClass('account')"
                        id="account_name" class="autocomplete"
                        :disabled="sending || !view"
                        v-model="instance.account_name"
                        md-input-name="account"
                        :md-options="list_account"
                        @md-selected="set_account"
                        @md-changed="get_list_accounts" >
                      <label for="account_name">Cuenta contable</label>
                      <template
                        slot="md-autocomplete-item" slot-scope="{ item, term }">
                        <md-highlight-text :md-term="term">
                          {{ item.name }}</md-highlight-text>
                      </template>
                      </md-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-50">
                    <md-field>
                      <label for="goods">Bienes</label>
                      <md-input type="number" @keyup="set_total()"
                        id="goods" name="goods"
                        @focus="$event.target.select()"
                        v-model.number="instance.goods" :disabled="sending || !view" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50" >
                    <md-field>
                      <label for="services">Servicios</label>
                      <md-input type="number" @keyup="set_total()"
                      @focus="$event.target.select()"
                      id="services" name="services"
                      v-model.number="instance.services" :disabled="sending || !view" />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-checkbox v-model="select_fuel" :disabled="!view"
                      @change="empty()"
                    >Es combustible?</md-checkbox>
                  <span v-if="select_fuel" style="color: #ff5252;
                  font-weight: 500;" for="services">SÍ</span>
                  <span v-if="!select_fuel" style="color: #ff5252;
                  font-weight: 500;" for="services">NO</span>
                  </div>
                </div>
                <div class="md-layout md-gutter" v-if="select_fuel">
                  <div class="md-layout-item md-small-size-50 md-medium-size-25 " >
                    <md-field>
                      <label for="gallon_price">Precio Galón</label>
                      <md-input type="number"
                        id="gallon_price" name="gallon_price"
                        @keyup="sum_combustibles()"
                        @focus="$event.target.select()"
                        v-model="instance.gallon_price" :disabled="sending || !view" />
                      </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 md-medium-size-25" >
                    <md-field>
                      <label for="gallon_price_diesel">Precio Galón diesel</label>
                      <md-input type="number"
                        id="gallon_price_diesel" name="gallon_price_diesel"
                        @keyup="sum_combustibles()"
                        @focus="$event.target.select()"
                        v-model="instance.gallon_price_diesel" :disabled="sending || !view" />
                      </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 md-medium-size-25" >
                    <md-field>
                      <label for="gallon_price_regular">Precio Galón regular</label>
                      <md-input type="number"
                        id="gallon_price_regular" name="gallon_price_regular"
                        @keyup="sum_combustibles()"
                        @focus="$event.target.select()"
                        v-model="instance.gallon_price_regular" :disabled="sending || !view" />
                      </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 md-medium-size-25" >
                    <md-field>
                      <label for="gallon_price_super">Precio Galón super</label>
                      <md-input type="number"
                        id="gallon_price_super" name="gallon_price_super"
                        @keyup="sum_combustibles()"
                        @focus="$event.target.select()"
                        v-model="instance.gallon_price_super" :disabled="sending || !view" />
                      </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 " >
                    <md-field>
                      <label for="diesel_fuel">Diesel</label>
                      <md-input type="number" @keyup="sum_combustibles"
                        id="diesel_fuel" name="diesel_fuel"
                        @focus="$event.target.select()"
                        v-model="instance.diesel_fuel" :disabled="sending || !view" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 " >
                    <md-field>
                      <label for="regular_fuel">Regular</label>
                      <md-input type="number" @keyup="sum_combustibles"
                      id="regular_fuel" name="regular_fuel"
                      @focus="$event.target.select()"
                      v-model="instance.regular_fuel" :disabled="sending || !view" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50 ">
                    <md-field>
                      <label for="super_fule">Super</label>
                      <md-input type="number" @keyup="sum_combustibles()"
                      @focus="$event.target.select()"
                      id="super_fuel" name="super_fuel"
                      v-model="instance.super_fuel" :disabled="sending || !view" />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter" v-if="select_fuel">
                  <div class="md-layout-item md-small-size-50">
                    <md-field>
                      <label for="idp">IDP</label>
                      <md-input type="number"
                      id="idp" name="idp" readonly
                      v-model="instance.idp" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50">
                      <md-field>
                      <label for="idp">Total Combustible (sin IDP)</label>
                      <md-input type="number"
                      id="fuel" name="fuel" readonly
                      v-model="instance.fuel" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50">
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-50">
                    <md-field>
                      <label for="idp">Base</label>
                      <md-input type="number"
                      id="base" name="base" readonly
                      v-model="instance.base" :disabled="sending" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-50">
                    <md-field>
                      <label for="iva_fuel">IVA</label>
                      <md-input type="number"
                        id="iva" name="iva" readonly
                        v-model="instance.iva" :disabled="sending" />
                      <span class="md-error">Debe de ingresar un tipo de combustible</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                      <md-field>
                      <label for="amount">Monto</label>
                      <md-input type="number"
                      id="amount" name="amount" readonly
                      v-model="instance.amount" :disabled="sending" />
                    </md-field>
                  </div>
                </div>
              </md-card-content>
              <md-progress-bar md-mode="indeterminate" v-if="sending" />
              <md-button v-if="newPurchase"
              type="submit" class="md-primary"
              @click.prevent='validatePurchase' :disabled="sending">Guardar</md-button>
              <md-button v-else
              type="submit" class="md-primary"
              @click.prevent='validatePurchase' :disabled="sending || !view"
              >Guardar cambios</md-button>
            </form>
          </md-card>
        </md-tab>
        <md-tab id="tab-partida" md-label="Partida"
            :md-disabled="tab" >
            <md-card-header>
              <md-card-header-text>
                <h3 for="total" >
                Total Documento: {{ this.instance.amount|format_number }}</h3>
              </md-card-header-text>
              <md-card-header-text >
                Número partida:
                <span class="headline" v-if="instance.partida_obj">
                 {{instance.partida_obj.numero_partida}} </span>
              </md-card-header-text>
              <md-card-header-text>
                Fecha partida:
                <span v-if="instance.partida_obj">
                {{instance.partida_obj.date_partida|to_date }} </span>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <br>
                <md-button class="md-icon-button"
                  @click="go_to(`/detalle_compras`)">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
            </md-card-header>

            <div v-if="instance.partida">
              <basePartida :id_partida = "instance.partida" :view = "view"
              v-if="is_show_component"/>
            </div>

              <div class="md-layout-item md-small-size-50" >
                <md-field v-if="hidden">
                  <md-input name="id"
                    id="id"
                    v-model="instance.partida" :disabled="sending" />
                </md-field>
              </div>
               <div class="moveRigth" v-if="view">
                <md-button class="md-primary"
                  @click="confirmPartida()" v-if="newPurchase">
                  Guardar Partida
                </md-button>
                <md-button class="md-primary" @click="confirmPartida()" v-else :disabled="!view">
                  Guardar Cambios
                </md-button>
              </div>
              <div class="md-layout-item md-small-size-100" v-if="view">
                <md-field class = "margin">
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida"
                      v-model="instance.partida_obj.descripcion" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100" v-else>
                <md-field >
                  <label for="descripcion_partida">Descripción partida</label>
                    <md-input name="descripcion_partida"
                      id="descripcion_partida" readonly
                      v-model="instance.partida_obj.descripcion" :disabled="sending" />
                </md-field>
              </div>
        </md-tab>
      </md-tabs>
      <br>
      <br>
    </div>
    <md-snackbar
      :md-active.sync="purchaseSaved">El documento {{ document }} ha sido actualizado!</md-snackbar>
    <md-snackbar
      :md-active.sync="partidaSaved">La partida ha sido actualizada!</md-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import moment from 'moment';
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Purchase_new_edit',
  created(){
    if (this.$route.params.id){
      this.instance.id = this.$route.params.id
      this.retrievePurchase();
    }else if (this.$route.params.idview) {
      this.instance.id = this.$route.params.idview
      this.view = false
      this.retrievePurchase();
    }
    this.retrievePeriod();

    this.iva = parseFloat(this.$store.state.company_obj.porcentaje_IVA/100)
    this.siva = parseFloat(this.$store.state.company_obj.porcentaje_IVA/100)+1
    this.get_data_storage('book_diary').then((d) => {
      this.book_diary = d.id;
      //this.$store.state.book_diary = d.id
      //let book_diary = this.get_data_storage('book_diary')
      //this.$store.state.book_diary = d.id
      if (this.book_diary) {
        this.$store.dispatch('set_book_diary', { bookDiary: d });
      }
    }).catch((error) => {
      Swal.fire({
        title: "Aviso",
        text: "Debe de ingresar libro diario, para crear partidas",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push('/libro_diario');
        }
      });
    });
  },
  mixins: [validationMixin],
  components: {
    basePartida: () => import('../components/BasePartida.vue'),
  },
  data() {
    return {
      list_provider: [],
      list_rubros: [],
      list_store: [],
      list_account: [],
      book_diary: {
        id: null
      },
      mes: '',
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        date_purchase: null,
        serie: null,
        number_invoice_purcharse: null,
        type_document: null,
        goods: 0.00,
        services: 0.00,
        diesel_fuel: 0.00,
        regular_fuel: 0.00,
        super_fuel: 0.00,
        iva: 0.00,
        idp: 0.00,
        fuel: 0.00,
        base: 0.00,
        amount: 0.00,
        gallon_price: 0.00,
        provider_name: null,
        account: null,
        provider: null,
        account: null,
        imports: 0.00,
        crear_partida: true,
        date_partida: null,
        descripcion: null,
        numero_partida: "1",
        tipo_partida: "CXP",
        descripcion: "",
        book_diary: null,
        id: '',
        partida_obj: {},
      },
      edit: false,
      view: true,
      is_show_component: true,
      url: 'purchase_book_detail/',
      url_provider: 'providers/',
      url_rubros: 'rubros/',
      url_stores: 'stores/',
      url_accounts: 'accounting_account/',
      url_diarybook: 'diary_book_detail/',
      url_partida: 'partida_detail/',
      url_period: 'periods/',
      url_accounts: 'accounting_account/',
      purchaseSaved: false,
      sending: false,
      document: null,
      value: null,
      select_fuel: false,
      newPurchase: true,
      iva: 0.00,
      siva: 0.00,
      totalDebe: 0.00,
      totalHaber: 0.00,
      diferencia: 0.00,
      rowsPerPage: 10,
      current_tab: "tab-documento",
      hidden: false,
      tab: true,
      partidaSaved: false,
      choosen_type_document: [
        {value:'D', text: 'DTE FACTURA'},
        {value:'F', text: 'FACTURA'},
        {value:'P', text: 'DTE FACTURA PEQUEÑO CONTRIBUYENTE'},
        {value:'Q', text: 'FACTURA PEQUEÑO CONTRIBUYENTE'},
        {value:'R', text: 'DTE NOTA CRÉDITO'},
        {value:'G', text: 'NOTA CRÉDITO'},
        {value:'B', text: 'DTE NOTA DÉBITO'},
        {value:'K', text: 'NOTA DÉBITO'},
        {value:'A', text: 'DTE FACTURA CAMBIARIA'},
        {value:'J', text: 'DTE FACTURA CAMBIARIA PEQUEÑO CONTRIBUYENTE'},
        {value:'Y', text: 'FACTURA CAMBIARIA'},
        {value:'M', text: 'COMPROBANTE'},
        {value:'E', text: 'DTE FACTURA ESPECIAL'},
        {value:'S', text: 'FACTURA ESPECIAL'},
      ],
    };

  },
  validations: {
    instance: {
      date_purchase: {
        required,
      },
      serie: {
        required,
      },
      number_invoice_purcharse: {
        required,
      },
      iva: {
        required,
      },
      type_document: {
        required,
      },
      provider_name: {
        required,
      },
    },
  },
  methods: {
    empty(select){
      if (!select){
        this.instance.gallon_price = 0.00,
        this.instance.gallon_price_regular = 0.00,
        this.instance.gallon_price_super = 0.00,
        this.instance.gallon_price_diesel = 0.00,
        this.instance.diesel_fuel = 0.00,
        this.instance.super_fuel = 0.00,
        this.instance.regular_fuel = 0.00
        this.instance.idp = 0,
        this.instance.iva = 0.00,
        this.instance.amount = 0.00,
        this.instance.fuel = 0,
        this.instance.base = 0.00
      }
    },
    reset_component () {
      this.is_show_component = false
      this.$nextTick(()=> {
        this.is_show_component = true
      })
    },
    set_account(obj) {  
      this.instance.account_name = obj.name;
      this.instance.account = obj.id;
    },
    get_list_accounts(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_accounts).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    go_to(url) {
      self.newPurchase = true
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    async get_data_storage(name) {
      return await JSON.parse(localStorage.getItem(name));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    set_provider (obj) {
      this.instance.provider_name = obj.name
      this.instance.provider = obj.id
      this.get_document()
    },
    get_list_providers(searchTerm) {
      const payload = {
        token: searchTerm,
        filter_suppliers: "True"
      };
      const self = this;
      this.sendRequest(payload, this.url_provider).then((data) => {
        self.list_provider = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    set_rubro (obj) {
      this.instance.rubro_name = obj.name
      this.instance.rubro = obj.id
    },
    get_list_rubros(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_rubros).then((data) => {
        self.list_rubros = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    get_list_stores(searchTerm=null) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
      }
      filter: "True"
      const self = this;
      this.sendRequest(payload, this.url_stores).then((data) => {
        self.list_store = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validatePurchase () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    check_date(){
      const self = this
      let date_i = moment(self.$store.state.date_period_i)
      if (this.instance.id){
        date_i = moment(this.instance.period_date)
      }
      let date_f = moment(date_i).subtract(2, 'months').format()
      let date_fin = moment(date_f).subtract(1, 'days').format()
      let fecha = moment(self.instance.date_purchase).isBetween(date_fin, date_i, 'days')
      let dif = date_i.diff(self.instance.date_purchase, 'days')
      if (fecha === false && dif >= 1){
        self.$swal.fire('Error!', 'Fecha de factura es mayor a 2 meses.', 'error')
        return false
      }
      if (fecha === false && dif <= -31){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }
    },
    saveOrUpdate () {
      if (!this.instance.serie) {
        this.$swal.fire('Error!', 'Serie es requerido.', 'error')
        return false
      }
      if (!this.instance.number_invoice_purcharse) {
        this.$swal.fire('Error!', 'Numero es requerido.', 'error')
        return false
      }
      if (!this.instance.date_purchase) {
        this.$swal.fire('Error!', 'Fecha es requerida.', 'error')
        return false
      }
      const self = this
      let date_i = moment(self.$store.state.date_period_i)
      if (this.instance.id){
        date_i = moment(this.instance.period_date)
      }
      let date_f = moment(date_i).subtract(2, 'months').format()
      let date_fin = moment(date_f).subtract(1, 'days').format()
      let fecha = moment(self.instance.date_purchase).isBetween(date_fin, date_i, 'days')
      let dif = date_i.diff(self.instance.date_purchase, 'days')
      if (fecha === false && dif >= 1){
        self.$swal.fire('Error!', 'Fecha de factura es mayor a 2 meses.', 'error')
        return false
      }
      if (fecha === false && dif <= -31){
        self.$swal.fire('Error!', 'Fecha de factura es de un mes posterior.', 'error')
        return false
      }else if (isNaN(dif)){
        self.$swal.fire('Error!', 'Ingreso una fecha errónea.', 'error')
        return false
      }

      const payload = self.instance
      
      this.sending = true

      if (!payload.goods) {
        payload.goods = 0
      }
      if (!payload.services) {
        payload.services = 0

      }
      if (dif < 1){
        payload.date_partida = this.instance.date_purchase
      }
      if (dif > 1){
        payload.date_partida = self.$store.state.date_period_i
        if (this.instance.id){
          payload.date_partida = this.instance.date_partida
        }
      }
      if (!this.instance.regular_fuel) {
        this.instance.regular_fuel = 0
      }
      if (!this.instance.super_fuel) {
        this.instance.super_fuel = 0 
      }
      if (!this.instance.diesel_fuel) {
        this.instance.diesel_fuel = 0
      }
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.document = `${self.instance.serie}-${self.instance.number_invoice_purcharse}`
          self.purchaseSaved = true
          self.sending = false
          self.instance = data.data
          self.reset_component()
          //setTimeout(() => self.go_to('/detalle_compras'), 2000);
          //self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.period = localStorage.getItem('period')
      payload.book_diary = self.$store.state.book_diary.id
      payload.date_i = self.$store.state.date_period_i
      
      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.office = localStorage.getItem('office')
      this.postRequest(payload).then(function (data) {
        self.document = `${self.instance.serie}-${self.instance.number_invoice_purcharse}`
        self.purchaseSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
        if (self.instance.partida){
          self.tab = false
        }
        self.current_tab = "tab-partida"
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    confirmPartida() {
      const self = this;
      if (parseFloat(this.diferencia) >= 0.01) {
        self.$swal.fire('Error!', 'Tiene diferencia en la partida.', 'error')
        return false
      }else{
        const payload = this.instance.partida_obj
        const self = this
        this.sending = true
        if (payload.id) {
          this.putRequestEncabezadoPartida(payload).then(function (data) {
            self.partidaSaved = true
            self.sending = false
            self.current_tab = "tab-documento"
            self.tab = true
          }).catch(function (error) {
            self.sending = false
            if (typeof (error.response.data) === 'object') {
              const result = error.response.data
              Object.entries(result).forEach(
                ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
              )
              return false
            }
            self.$swal.fire('Error!', error.response.data, 'error')
          })
          return
        }

      }
    },
    async retrievePurchase () {
      const self = this
      const payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.newPurchase = false
        self.instance = data.data
        let comb = parseFloat(self.instance.fuel)
        if (comb >= 0.01 || comb < -0.01){
          self.select_fuel = true
        }
        if (self.instance.partida){
          self.tab = false
        }
      }).catch(function (error) {
        if (!error.response) {
          self.$swal.fire('Error!', error, 'error')
          return false
        }
        self.handlerError(error)
      })
    },
    async retrievePeriod () {
      const self = this
      const payload = {
        id: localStorage.getItem('period')
      }

      await this.retrieveRequestPeriod(this.url_period, payload).then(function (data) {
        //Fecha inicio periodo
        self.mes = data.data.period
        // self.$store.state.date_period_i = data.data.date
        self.$store.dispatch('set_date_period_i', { datePeriodI: data.data.date });

      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async putRequestEncabezadoPartida (payload) {
      const result = await this.$http.put(`${this.url_diarybook}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
    async retrieveRequestPeriod (url_period, _payload) {
      const result = await this.$http.get(url_period + _payload.id + '/', { params: _payload })
      return result
    },
    get_document(){
      const self = this
      if (self.instance.date_purchase && self.instance.serie
        && self.instance.number_invoice_purcharse){
          self.verify_document()
        }
    },
    verify_document(){
      if (this.instance.id) return false
      const self = this
      const payload = {
        verify: true,
        date_purchase: this.instance.date_purchase,
        serie: this.instance.serie,
        number_invoice_purcharse: this.instance.number_invoice_purcharse,
        company: this.$store.state.company_obj.id,
        provider: this.instance.provider,
      }
      this.postRequest(payload).then(function (data) {

      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
        return false
      })
    },
    round_up(n, decimals=0) {
        let multiplier = (10 ** decimals)
        return Math.ceil(n * multiplier) / multiplier
    },
    sum_combustibles (iva_incluido=true){
      //reset 
      let temp_idp = parseFloat(0);
      let temp_idp_general = parseFloat(0);
      let temp_fuel = parseFloat(0);

      if (!this.instance.gallon_price_regular && this.instance.regular_fuel && this.gallon_price){
        this.instance.gallon_price_regular = this.gallon_price
      }
      if (!this.instance.gallon_price_super && this.instance.super_fuel && this.gallon_price){
        this.instance.gallon_price_super = this.gallon_price
      }
      if (!this.instance.gallon_price_diesel && this.instance.diesel_fuel && this.instance.gallon_price){
        this.$set(this.instance, 'gallon_price_diesel', this.instance.gallon_price)
      }

      let super_fuel = parseFloat(this.instance.super_fuel)
      let diesel = parseFloat(this.instance.diesel_fuel)
      let regular = parseFloat(this.instance.regular_fuel)

      let galon_regular = parseFloat(this.instance.gallon_price_regular)
      let galon_super = parseFloat(this.instance.gallon_price_super)
      let galon_diesel = parseFloat(this.instance.gallon_price_diesel)

      if (iva_incluido && super_fuel > 0 && galon_super > 0){
        temp_idp = parseFloat((super_fuel / galon_super)*4.7)
        temp_fuel += parseFloat(super_fuel - temp_idp);
        temp_idp_general += temp_idp;
      }

      if (iva_incluido && regular > 0 && galon_regular > 0){
        temp_idp = parseFloat((regular / galon_regular)*4.6)
        temp_fuel += parseFloat(regular - temp_idp)
        temp_idp_general += temp_idp;
      }
      if (iva_incluido && diesel > 0 && galon_diesel > 0){
        temp_idp = parseFloat((diesel / galon_diesel)*1.3)
        temp_fuel += parseFloat(diesel - temp_idp)
        temp_idp_general += temp_idp;
      }
      this.$set(this.instance, 'idp', parseFloat(temp_idp_general).toFixed(5) );
      this.$set(this.instance, 'fuel', parseFloat(temp_fuel).toFixed(5) );
      setTimeout(() => {
        console.log("this is the first message");
        this.set_total();
      }, 100);
      
    },
    set_total (iva_incluido=true){
      if (this.instance.type_document === 'P' || this.instance.type_document === 'Q' ){
        this.instance.base = (parseFloat(temp_obj.goods) + parseFloat(temp_obj.services)) + parseFloat(this.instance.fuel) + parseFloat(this.instance.idp)
        this.instance.amount = this.instance.base
        return 
      }
      let temp_obj = Object.assign( {}, this.instance )
      if (iva_incluido){
        if (!temp_obj.services) {
          temp_obj.services = 0
        }
        if (!temp_obj.goods) {
          temp_obj.goods = 0
        }
        if (temp_obj.fuel){
          if (temp_obj.goods != 0){
              temp_obj.goods = parseFloat(temp_obj.goods) - parseFloat(temp_obj.fuel + temp_obj.idp)
          }
          temp_obj.goods = parseFloat(temp_obj.goods) + parseFloat(temp_obj.fuel)
          this.$set(this.instance, 'goods', temp_obj.goods)
          
        }
        
        let total = (parseFloat(temp_obj.goods) + parseFloat(temp_obj.services))


        temp_obj.base = parseFloat(total / this.siva).toFixed(5)
        temp_obj.iva = this.round_up(temp_obj.base  * this.iva, 5)
        if (temp_obj.idp){
          total += parseFloat(temp_obj.idp)
        }
        temp_obj.amount = parseFloat(total).toFixed(5)
        
        this.$set(this.instance, 'base', temp_obj.base)
        this.$set(this.instance, 'iva', temp_obj.iva)
        this.$set(this.instance, 'amount', temp_obj.amount)      
      }
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    },
    format_number: function (value) {
      if (!value) return ''
        return isNaN(value) ? 0 : parseFloat(value).toFixed(2)
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('DD/MM/YYYY')
    },
    to_text_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('MMMM Y')
    },
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .customWidthClass{
    width: 10%;
  }
  .moveRigth{
    display: block;
    float: right;
    margin-right: 20%;
    margin-top: 2%;
  }
  .period{
    float: center;
  }
  .moveLeft{
    display: block;
    float: left;
  }
  .size{
    width: 150px;
    margin-left: 20px;
  }
  .margin{
    margin-bottom: 40px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
